module.exports = [{
      plugin: require('/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-113221012-1"},
    },{
      plugin: require('/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
