// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-js": () => import("/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-pages-404-js": () => import("/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-check-connection-js": () => import("/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/src/pages/check-connection.js" /* webpackChunkName: "component---src-pages-check-connection-js" */),
  "component---src-pages-index-tsx": () => import("/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import("/Users/jcu/Documents/_CODE/GAE/help.dadbox.eu/.cache/data.json")

